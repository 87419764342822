.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.home-navbar-container {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 100;
  position: sticky;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}

.home-navbar {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}

.home-logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.home-image {
  width: 50px;
  object-fit: cover;
}

.home-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.home-link {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}

.home-link1 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}

.home-link2 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}

.home-link3 {
  text-decoration: none;
}

.home-cta-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.home-cta-btn {
  color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 34px;
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-100);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-burger-menu {
  display: none;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
}

.home-icon {
  width: 36px;
  height: 36px;
}

.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}

.home-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}

.home-image1 {
  width: 50px;
  object-fit: cover;
}

.home-container1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-icon02 {
  width: 24px;
  height: 24px;
}

.home-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-links-container1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}

.home-link4 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}

.home-link5 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}

.home-link6 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}

.home-link7 {
  text-decoration: none;
}

.home-cta-btn1 {
  color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 34px;
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-100);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-bot {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
}

.home-social-links-container {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}

.home-icon04 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}

.home-icon06 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}

.home-icon08 {
  width: 24px;
  height: 24px;
}

.home-hero {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}

.home-hero-text-container {
  width: 35%;
  display: flex;
  align-items: flex-start;
  margin-left: 50px;
  flex-direction: column;
}

.home-heading {
  margin-bottom: var(--dl-space-space-tripleunit);
}

.home-text {
  color: var(--dl-color-gray-500);
  margin-bottom: var(--dl-space-space-tripleunit);
}

.home-cta-btn2 {
  color: var(--dl-color-gray-white);
  align-self: stretch;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-100);
}

.home-image2 {
  width: 649px;
  object-fit: contain;
}

.home-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}

.home-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
}

.home-heading-container {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}

.home-text05 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}

.home-cards-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.home-feature-card {
  width: 30%;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}

.home-image3 {
  width: 362px;
  height: 188px;
  object-fit: cover;
  margin-bottom: 20px;
}

.home-heading1 {
  margin-bottom: var(--dl-space-space-tripleunit);
}

.home-text09 {
  color: var(--dl-color-gray-500);
  text-align: center;
}

.home-feature-card1 {
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}

.home-image4 {
  width: 376px;
  height: 194px;
  object-fit: cover;
  margin-bottom: 20px;
}

.home-heading2 {
  margin-bottom: var(--dl-space-space-tripleunit);
}

.home-text17 {
  color: var(--dl-color-gray-500);
  text-align: center;
}

.home-feature-card2 {
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}

.home-image5 {
  width: 360px;
  height: 196px;
  object-fit: cover;
  margin-bottom: 20px;
}

.home-heading3 {
  margin-bottom: var(--dl-space-space-tripleunit);
}

.home-text22 {
  color: var(--dl-color-gray-500);
  text-align: center;
}

.home-feature-card3 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-tenunits);
  padding-top: var(--dl-space-space-doubleunit);
  margin-right: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}

.home-image6 {
  width: 391px;
  height: 157px;
  object-fit: cover;
  margin-bottom: 20px;
}

.home-heading4 {
  margin-bottom: var(--dl-space-space-tripleunit);
}

.home-text28 {
  color: var(--dl-color-gray-500);
  text-align: center;
}

.home-services {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  flex-direction: row;
  justify-content: space-between;
}

.home-service-card {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}

.home-card-content {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-text36 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}

.home-text37 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}

.home-service-card1 {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}

.home-card-content1 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-text40 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}

.home-text41 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}

.home-service-card2 {
  width: 49%;
  height: 201px;
  display: flex;
  min-height: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}

.home-card-content2 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-text44 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}

.home-text45 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}

.home-service-card3 {
  width: 49%;
  height: 163px;
  display: flex;
  min-height: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}

.home-card-content3 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-text48 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}

.home-text49 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}

.home-about-us {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: flex-start;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}

.home-heading-container1 {
  width: 35%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}

.home-text55 {
  margin-bottom: var(--dl-space-space-tripleunit);
}

.home-secondary-text {
  color: var(--dl-color-gray-500);
  margin-bottom: var(--dl-space-space-tripleunit);
}

.home-cta-btn3 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: flex-start;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-100);
}

.home-text-container {
  width: 45%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-text56 {
  color: var(--dl-color-gray-500);
}

.home-cta-btn4 {
  color: var(--dl-color-gray-white);
  width: 80%;
  display: none;
  align-self: flex-start;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-100);
}

.home-section-separator1 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}

.home-contact {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: flex-start;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}

.home-content-container {
  width: 50%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  align-self: stretch;
  align-items: flex-start;
  border-color: var(--dl-color-gray-700);
  border-width: 1px;
  flex-direction: column;
  justify-content: flex-start;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-text63 {
  margin-top: var(--dl-space-space-fiveunits);
  margin-bottom: var(--dl-space-space-fiveunits);
}

.home-locations-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: row;
}

.home-location-1 {
  width: 431px;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}

.home-heading5 {
  font-size: 24px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-doubleunit);
}

.home-adress {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}

.home-icon10 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}

.home-email {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}

.home-icon12 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}

.home-phone {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}

.home-icon14 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}

.home-image7 {
  margin-right: 10%;
  margin-left: 10%;
  width: 50%;
  object-fit: cover;
}

@media(max-width: 991px) {
  .home-hero {
    flex-direction: column-reverse;
  }

  .home-hero-text-container {
    width: 80%;
    margin-left: 0px;
  }

  .home-cta-btn2 {
    width: 40%;
  }

  .home-image2 {
    width: 80%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }

  .home-feature-card {
    align-self: center;
  }

  .home-about-us {
    flex-direction: column;
  }

  .home-heading-container1 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-tripleunit);
  }

  .home-cta-btn3 {
    width: 40%;
    display: none;
  }

  .home-text-container {
    width: 100%;
  }

  .home-cta-btn4 {
    width: 280px;
    display: block;
    margin-top: var(--dl-space-space-doubleunit);
  }

  .home-locations-container {
    flex-wrap: wrap;
  }

  .home-location-1 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }

  .home-image7 {
    align-self: center;
  }
}

@media(max-width: 767px) {
  .home-links-container {
    display: none;
  }

  .home-burger-menu {
    display: flex;
    padding: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-tripleunit);
    background-color: var(--dl-color-gray-black);
  }

  .home-icon {
    fill: var(--dl-color-gray-white);
  }

  .home-heading {
    text-align: center;
  }

  .home-text {
    text-align: center;
  }

  .home-cta-btn2 {
    width: auto;
    align-self: center;
  }

  .home-heading-container {
    width: 100%;
  }

  .home-cards-container {
    align-items: center;
    flex-direction: column;
  }

  .home-feature-card {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }

  .home-feature-card1 {
    width: 70%;
  }

  .home-feature-card2 {
    width: 70%;
  }

  .home-feature-card3 {
    width: 70%;
  }

  .home-card-content {
    margin: 0px;
  }

  .home-card-content1 {
    margin: 0px;
  }

  .home-card-content2 {
    margin: 0px;
  }

  .home-card-content3 {
    margin: 0px;
  }

  .home-about-us {
    align-items: center;
  }

  .home-heading-container1 {
    align-items: center;
  }

  .home-text55 {
    text-align: center;
  }

  .home-secondary-text {
    text-align: center;
  }

  .home-contact {
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
  }

  .home-content-container {
    width: 100%;
  }

  .home-image7 {
    width: 100%;
  }
}

@media(max-width: 479px) {
  .home-cta-btn {
    font-size: 16px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }

  .home-burger-menu {
    margin-left: var(--dl-space-space-doubleunit);
  }

  .home-cta-btn1 {
    font-size: 16px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }

  .home-hero-text-container {
    width: 100%;
  }

  .home-image2 {
    width: 100%;
  }

  .home-feature-card {
    width: 100%;
  }

  .home-feature-card1 {
    width: 100%;
  }

  .home-feature-card2 {
    width: 100%;
  }

  .home-feature-card3 {
    width: 100%;
  }

  .home-service-card {
    width: 100%;
  }

  .home-service-card1 {
    width: 100%;
  }

  .home-service-card2 {
    width: 100%;
    margin-bottom: var(--dl-space-space-fourunits);
  }

  .home-service-card3 {
    width: 100%;
  }

  .home-secondary-text {
    text-align: center;
  }

  .home-content-container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .home-text63 {
    text-align: center;
  }

  .home-locations-container {
    margin-bottom: 0px;
  }

  .home-location-1 {
    margin-right: 0px;
  }
}